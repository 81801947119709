import React from "react";
import { graphql } from 'gatsby';
import Layout from "../../components/layout";
import SEO from "../../components/seo";


import Gallery from '@browniebroke/gatsby-image-gallery'

const Fonster = ({ data }) => {
    const sky = data.sky.edges.map(({ node }) => node.childImageSharp)
    const trees = data.trees.edges.map(({ node }) => node.childImageSharp)

    return (
        <Layout>
            <SEO title="Bildgalleri Takfönster" />
            <div className="mainWrapper">
                <h1>Bildgalleri</h1>
                <p>Här visas de bilder som går att välja på till samtliga av våra takfönster.</p>
                {/* <h2>Favoriter</h2>
                <p>Dessa är våra personliga favorieter för en unik utsikt.</p>
                <Gallery images={images} /> */}
                <h2>Himmel</h2>
                <Gallery images={sky} />
                <h2>Himmel med Träd</h2>
                <Gallery images={trees} />
            </div>
        </Layout>
    )

}

export const pageQuery = (graphql`
query ImagesForGalleryRoof {
    sky: allFile(
      filter: { relativeDirectory: { eq: "gallery/takfonster/himmel" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
    trees: allFile(
      filter: { relativeDirectory: { eq: "gallery/takfonster/trad" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 500
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
    }
}
}
`)

export default Fonster;